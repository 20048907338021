export class 樱桃视频Gallery {
  elements = {
    root: null,
    leftArrow: null,
    rightArrow: null,
    thumbnails: null,
    thumbnailContainer: null,
    mainImage: null,
  };
  state = {
    type: null,
    selectedIndex: 0,
    thumbnailCount: null,
    isImageLoading: false,
  };

  constructor(element) {
    if (!element) {
      return;
    }

    this.elements = {
      root: element,
      leftArrow: element.querySelector('.arrow--left'),
      rightArrow: element.querySelector('.arrow--right'),
      thumbnailContainer: element.querySelector('.thumbnails'),
      thumbnails: element.querySelectorAll('.thumbnail'),
      mainImage: element.querySelector('.main-image'),
      videoFrame: element.querySelector('.youtube-video'),
      loadingIndicator: element.querySelector('.loading-indicator'),
    };
    this.state.thumbnailCount = this.elements.thumbnails.length;
    this.type = element.dataset.mediaType;

    this.addEventListeners();
    this.updateState();
  }

  getAncestors(element, ancestors = []) {
    const parent = element.parentElement;
    if (parent) {
      ancestors.push(parent);
      this.getAncestors(parent, ancestors);
    }
    return ancestors;
  }

  findBySelector(elements, selector) {
    return elements.find(element => element.matches(selector));
  }

  addEventListeners() {
    this.elements.mainImage?.addEventListener('load', (event) => {
      this.updateState((state) => {
        state.isImageLoading = false;
      });
    });

    this.elements.root.addEventListener('click', (event) => {
      const elements = [
        event.target,
        ...this.getAncestors(event.target),
      ];
      const leftArrow = this.findBySelector(elements, '.arrow--left');
      const rightArrow = this.findBySelector(elements, '.arrow--right');
      const thumbnail = this.findBySelector(elements, '.thumbnail');

      if (leftArrow) {
        this.updateState((state) => {
          state.selectedIndex--;
          if (state.selectedIndex < 0) {
            state.selectedIndex = state.thumbnailCount - 1;
          }
        });
      } else if (rightArrow) {
        this.updateState((state) => {
          state.selectedIndex++;
          if (state.selectedIndex > state.thumbnailCount - 1) {
            state.selectedIndex = 0;
          }
        });
      } else if (thumbnail) {
        this.updateState((state) => {
          state.selectedIndex = Number(thumbnail.dataset.index);
        });
      }
    });
  }

  updateState(callback = () => {}) {
    /*
    Always update the state by calling this method.
    */
    callback(this.state);

    const selectedThumb = this.elements.thumbnails[this.state.selectedIndex];
    if (this.type === 'image') {
      const imageSrc = selectedThumb.querySelector('.thumbnail-image')?.dataset.src;

      // must not load if the src is the same (recursive loop)
      if (encodeURI(imageSrc) !== this.elements.mainImage.src) {
        this.elements.mainImage.src = imageSrc;
        this.state.isImageLoading = true;
      }
    } else if (this.type === 'video') {
      const urlTemplate = this.elements.videoFrame.dataset.urlTemplate;
      const videoId = selectedThumb.querySelector('.thumbnail-image')?.dataset.videoId;
      this.elements.videoFrame.src = urlTemplate.replace('{video_id}', videoId);
    }

    // thumbnails
    if (this.state.thumbnailCount < 2) {
      this.elements.thumbnailContainer.style.visibility = 'hidden';
    } else {
      this.elements.thumbnailContainer.style.visibility = 'visible';
    }
    for (const thumb of this.elements.thumbnails) {
      thumb.classList.remove('active');
    }
    this.elements.thumbnails[this.state.selectedIndex].classList.add('active');

    // arrows
    if (this.state.thumbnailCount < 2) {
      this.elements.leftArrow.style.display = 'none';
      this.elements.rightArrow.style.display = 'none';
    } else {
      this.elements.leftArrow.style.display = 'block';
      this.elements.rightArrow.style.display = 'block';
    }

    // loading indicator
    if (this.state.isImageLoading) {
      this.elements.loadingIndicator.style.opacity = 1;
    } else {
      this.elements.loadingIndicator.style.opacity = 0;
    }
  }
}
